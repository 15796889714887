import { graphql, Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import { v4 } from 'uuid';
import Article from '../../components/Article';
import Layout from '../../components/Layout';
import SEO from '../../components/Seo';

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: 1rem;
  width: 100%;
  @media (min-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const Blog = ({ data: { allContentfulBlogPost } }) => {
  return (
    <Layout>
      <SEO title="Blog" uri="/blog" />
      <Grid>
        {allContentfulBlogPost.edges.map(({ node }) => (
          <Link to={`/blog/${node.slug}`} key={v4()} aria-label={node.title}>
            <Article post={node} />
          </Link>
        ))}
      </Grid>
    </Layout>
  );
};

export const query = graphql`
  query {
    allContentfulBlogPost(sort: { order: DESC, fields: publishDate }) {
      edges {
        node {
          id
          slug
          title
          richBody {
            raw
          }
          metaDescription
          publishDate
          heroImage {
            title
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 568
                  height: 400
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }
  }
`;

export default Blog;
