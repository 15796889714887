import * as React from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { renderRichText } from 'gatsby-source-contentful/rich-text';

import { Post } from '../../types/Post';

type Props = {
  post: Post;
};

const StyledH2 = styled.h2`
  margin: 0 0 1rem 0;
  color: #444444;
  transition: color 0.25s linear;
`;

const StyledArticle = styled.article`
  transition: border-color 0.25s linear;
  color: #444444;
  border: 1px solid #e8e8e8;
  height: 100%;

  &:hover {
    border-color: #444444;
  }
`;

const StyledDiv = styled.div`
  padding: 1rem;
`;

const StyledTime = styled.time`
  margin-right: 1rem;
  font-size: 0.75rem;
  color: #444444;
`;

const PostMeta = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const StyledP = styled.p`
  margin: 0 0 1rem 0;
  font-size: 1rem;
  line-height: 1.5rem;
`;

const Article = ({ post }: Props) => {
  const image = getImage(post.heroImage.localFile);
  const excerpt = renderRichText(post.richBody);

  return (
    <StyledArticle>
      <GatsbyImage image={image} alt="" />
      <StyledDiv>
        <StyledH2>{post.title}</StyledH2>
        <StyledP>
          {post.metaDescription?.length >= 150
            ? `${post.metaDescription.slice(0, 150)}...`
            : post.metaDescription}
        </StyledP>
        <PostMeta>
          <StyledTime dateTime={post.publishDate}>
            {format(new Date(post.publishDate), 'd/L/y')}
          </StyledTime>
        </PostMeta>
      </StyledDiv>
    </StyledArticle>
  );
};

export default Article;
